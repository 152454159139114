import React from 'react'
import Accueil from './accueil'
import './style.scss'

export default function Index() {

  return (

    <div>
      <Accueil />
    </div>
    
  )
}